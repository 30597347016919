<template>
  <div class="response success fadein" ref="response" v-show="response">
    {{ response }}
  </div>
  <div class="response error fadein" ref="errors" v-show="errors.length">
    <template v-for="(error, idx) in errors" :key="idx">
      <template v-if="errors.length > 1">
        <li>{{ error }}</li>
      </template>
      <template v-else>
        {{ error }}
      </template>
    </template>
  </div>
  <div class="loader" v-if="loading"></div>
  <form class="form" ref="form" @submit.prevent="submit_form">
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__user.png" alt="" />
        </div>
        <div class="field__title">ID</div>
      </div>
      <input
        type="text"
        name="login"
        class="input"
        v-model="form.username"
        placeholder="username"
        autocomplete
        required
      />
    </div>
    <!-- END  field -->

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__mail.png" alt="" />
        </div>
        <div class="field__title">E-mail</div>
      </div>
      <input
        type="text"
        name="email"
        class="input"
        v-model="form.email"
        autocomplete
        required
      />
    </div>
    <!-- END  field -->

    <div class="protection protection_mb">
      <div class="protection__captcha">
        <vue-recaptcha
          v-if="showRecaptcha"
          :sitekey="recaptcha_sitekey"
          size="normal"
          theme="dark"
          :tabindex="0"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha"
        >
        </vue-recaptcha>
      </div>
    </div>

    <div class="form__btn-box">
      <button
        class="button"
        name="restorePassword"
        :class="{ disabled: this.btn_disabled }"
        :disabled="this.btn_disabled"
        type="submit"
      >
        <span class="button__content">Restore password</span>
      </button>
    </div>
  </form>
</template>

<script>
import cfg_axios from "@/config/cfg_axios";
import axios from "axios";
import vueRecaptcha from "vue3-recaptcha2";
import { recaptcha_sitekey } from "@/assets/js/recaptcha";

export default {
  name: "RestorePassword",
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      recaptcha_sitekey,
      showRecaptcha: true,
      captcha: false,
      form: {
        username: null,
        email: null,
      },
      errors: [],
      response: null,
      btn_disabled: false,
      loading: false,
    };
  },
  methods: {
    recaptchaVerified() {
      this.captcha = true;
    },
    recaptchaExpired() {
      this.captcha = false;
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.captcha = false;
    },
    async submit_form() {
      this.loading = true;
      this.btn_disabled = true;
      this.errors = [];
      this.response = null;

      if (!this.captcha) {
        this.errors.push("You should verify captcha");
        setTimeout(() => {
          this.btn_disabled = false;
          this.errors = [];
        }, 3000);
        this.loading = false;
        return;
      }

      if (this.errors.length) {
        this.loading = false;
        return;
      }

      let form_data = new FormData(this.$refs.form);

      await axios
        .post(cfg_axios.base_url + "/account/restore_password", form_data, {
          headers: cfg_axios.headers,
        })
        .then((response) => {
          this.response = response.data;
        })
        .catch((error) => {
          this.errors.push(
            error.response.data.messages[
              Object.keys(error.response.data.messages)[0]
            ]
          );
        })
        .finally(() => {
          this.recaptchaExpired();
          setTimeout(() => {
            this.btn_disabled = false;
            this.errors = [];
            this.response = null;
          }, 10000);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
